import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, TableActions, FormContent } from '@front/squirtle'
import { Button, Box, Grid, Typography, StepLabel, StepContent } from '@mui/material'
import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonGlobalAction from './ButtonGlobal'

const Title = props =>
  <Grid container item xs={12}>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('history.recent')} </Typography>
    </StepLabel >
  </Grid>

const HistoryGlobal = ({ record_id, navigate, ...props }) => <>
  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
    <Button onClick={() => navigate(`/history?driver=${record_id}`)} disabled={!record_id} sx={{ textTransform: 'none' }}>
      <Typography> {I18n.t('history.see')} </Typography>
    </Button>
  </Box>
</>

const HistorySection = ({ record_id, navigate }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const filterToState = useCallback(Callbacks.filterToStateHandler(record_id), [record_id])

  return <Grid container rowSpacing={3}>
    <Title />
    <Grid item xs={12}>
      <StepContent>
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <HistoryGlobal record_id={record_id} navigate={navigate} />
          <SearchFrame
            sort={['createdAt DESC']}
            model_name={'action'}
            populate={['operator.info', 'group']}
            allowCreate={false}
            createPlacement={'bottom'}
          >
            <SearchForm
              filterToForm={filterToForm}
              formToFilter={formToFilter}
              filterToState={filterToState}
              submitOnMount
              disableButton
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable
              tableProps={{
                onRowClick: null,
                enableToolbar: false,
                enablePagination: true,
                enableRowActions: false,
                enableRowSelection: false,
                enableGlobalActions: true,
                enableGlobalActionsPlacement: 'bottom',
                disableCancelRowLabel: true,
                disableSaveRowLabel: true
              }}
            >
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
              <TableActions><ButtonGlobalAction record_id={record_id} /></TableActions>
            </SearchTable>
          </SearchFrame >
        </Box >
      </StepContent>
    </Grid>
  </Grid>
}

export default React.memo(HistorySection)
